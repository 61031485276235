import React from 'react';

import { ImageButton, HomeCustomisation } from '@fingermarkglobal/cringer.components';

import DefaultBackground from '../../../assets/images/background-home.png';

const PrimaryHome = ({
  className = '',
  accessibilityEnabled = false,
  primaryAction,
  secondaryAction,
  hasBothActionButtons,
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackground,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className={`flex flex-col z-10 justify-between items-center h-full w-full overflow-y-hidden ${className}`}
      >
        <div
          data-test="home-upper"
          className={`flex flex-col justify-center items-center w-full space-y-16 ${
            accessibilityEnabled ? 'mt-64' : ''
          }`}
        >
          <div
            data-test="home-services"
            className={`${
              hasBothActionButtons ? 'grid grid-cols-2 gap-16 w-4/5' : 'justify-center w-2/5'
            }  ${accessibilityEnabled ? 'mt-145' : 'mt-237'}`}
          >
            {!!secondaryAction && (
              <ImageButton
                dataTest="button-secondary"
                className={`bg-eat-in ${hasBothActionButtons ? '' : 'important-h-24'}`}
                onClick={secondaryAction}
              />
            )}

            {!!primaryAction && (
              <ImageButton
                dataTest="button-primary"
                className={`bg-take-away ${hasBothActionButtons ? '' : 'important-h-24'}`}
                onClick={primaryAction}
              />
            )}
          </div>
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
